import axios from 'axios';
import { accessToken, logout, setAxiosInterceptors } from '@lendica/auth';

export const baseURL = 'https://api.golendica.com/api/v1';
export const axiosInstance = axios.create({ baseURL });
setAxiosInterceptors(baseURL, axiosInstance, () => {
    // TODO: clear accounts and redirect to login
    window.location.replace(`${window.location.origin}`);
});

export const fetchActiveConnections = async (hanldeUserError = () => null) => {
    let response = {};
    try {
        // response = await axiosInstance.get('company/connections', {
        //     headers: { Authorization: `Bearer ${accessToken.get()}` },
        // });
        response = await axiosInstance.get('company/connections');
    } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
            hanldeUserError();
            window.location.replace(`${window.location.origin}`);
        }
    }

    return await response.data;
};

export const fetchCompanySummary = async (hanldeUserError = () => null) => {
    let response = {};
    try {
        // response = await axiosInstance.get('company/summary', {
        //     headers: { Authorization: `Bearer ${accessToken.get()}` },
        // });
        response = await axiosInstance.get('company/summary');
    } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
            hanldeUserError();
            window.location.replace(`${window.location.origin}`);
        }
    }

    return await response.data;
};
